// @import "../../assets/styles/variables";
@use "../../assets/styles/variables";
.btn:global(.MuiButton-root) {
    font-weight: bold;
    font-size: 1.6rem;
}

:global(.cancelBtn):global(.MuiButtonBase-root) {
    color: variables.$gray-light-2;
    background: variables.$gray-bg;
    width: 50%;
    // margin-right: 1.6rem;
    font-weight: bold;
}
:global(.primaryBtn):global(.MuiButtonBase-root) {
    color: variables.$text-white;
    width: 50%;
    font-weight: bold;
}

:global(.MuiButton-containedInfo) {
    &:hover {
        color: variables.$background-paper;
    }
}

.saleBtn:global(.MuiButton-root) {
    padding: 2px 2rem;
    min-height: 34px;
}

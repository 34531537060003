@import "../../assets/styles/variables";

.customInput {
    & > :global(.MuiInputBase-root.MuiFilledInput-root) {
        background: $background-paper;
        border-radius: $border-radius-default;
        border: 1px solid $border-gray-2;
        // padding: 1rem;
        &:hover {
            background: $info-light;
        }
        & > :global(.MuiSelect-select.MuiFilledInput-input) {
            &:focus-within {
                background: $info-light;
            }
        }
    }

    input:global(.MuiInputBase-input.MuiFilledInput-input) {
        padding: 1.3rem;
    }
    input:global(.MuiInputBase-input.MuiOutlinedInput-input) {
        padding: 1.3rem;
    }

    & > :global(.MuiInputBase-root.MuiOutlinedInput-root) {
        background: $background-paper;
        border-radius: $border-radius-default;
        &:hover {
            background: $info-light;
        }
        &:focus-within {
            background: $info-light;
        }
    }
}

:global(.MuiInputBase-root.MuiOutlinedInput-root) {
    margin-bottom: 2.4rem;
    &:global(.Mui-error) {
        margin-bottom: 0;
    }
}
.controlShowPassword {
    cursor: pointer;
    padding-left: 8px;
    display: grid;
    place-content: center;
}

.textCounter {
    font-size: 1.6rem;
    color: $gray-600;
    margin-top: 0.8rem;
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
}
.error {
    color: $error-main;
}

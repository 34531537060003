@use "sass:map";

$rem-base: 16px;
$drawer-width: 240px;
$topNav-Height: 56px;

// =============== colors =================
$primary-light: #7d57fb;
$primary-main: #5d2efb;
$primary-dark: #4120af;

$secondary-light: #967483;
$secondary-main: #7c5264;
$secondary-dark: #563946;

$error-light: #c74747;
$error-main: #ba1a1a;
$error-dark: #821212;

$warning-light: #f11919;
$warning-main: #e4404b;
$warning-dark: #be1f1f;

$info-light: #f7f1fd;
$info-main: #c9beff;
$info-dark: #9385dd;

$background-60: #fdf8fd91;
$background: #fdf8fd;
$background-paper: #fff;

$bg-gradient-1: linear-gradient(271.29deg, rgba(142, 94, 251, 0.8) 0%, rgba(58, 152, 207, 0.9) 100%);
$bg-gradient-2: linear-gradient(50deg, rgb(227, 215, 255) 0%, rgb(240, 236, 250) 40%, rgb(212, 240, 255) 100%);
$text-primary: $primary-main;
$text-secondary: #606060;
$text-black: #1e1e1e;
$text-gray-1: #606060;
$text-gray-2: #605d62;
$text-white: white;
$text-error: $error-main;
$text-deepblue: #1b0063;

$gray-light-1: rgba(0, 0, 0, 0.22);
$gray-light-2: #909090;
$gray-light-3: #666;
$gray-light-4: #c1c1c1;
$gray-bg: #eaeaea;
$border-gray-1: #c9c5ca;
$border-gray-2: #79747e;

$gray-100: #f7f2f7;
$gray-500: #939094;
$gray-600: #868686;
$gray-700: #605d62;
$gray-900: #313033;

$purple-50: #f4effa;
$purple-100: #e5deff;
$purple-300: #927dff;
$purple-400: #8e5efb;
$purple-600: #5f52a6;
$purple-800: #2f009b;
$purple-900: #1b0063;

// =============== units =================
$border-radius-default: 4px;
$font-size-default: 1.6rem;

// =============== mapping =================

$flex-direction: (
    row: row,
    row-r: row-reverse,
    column: column,
    column-r: column-reverse,
);
$justify-content: (
    start: flex-start,
    center: center,
    end: flex-end,
    between: space-between,
    around: space-around,
);
$align-items: (
    start: flex-start,
    center: center,
    end: flex-end,
    between: space-between,
    around: space-around,
);

$breakpoints: (
    mobile: 500,
    tablet-sm: 768,
    tablet-lg: 900px,
    tablet: 1024,
    desktop: 1360,
    mobile-280: 280px,
    tablet-719: 719px,
    desktop-1080: 1080px,
);

$mui-breakpoints: (
    xs: 0,
    sm: 600px,
    lg: 1200px,
    xl: 1536px,
);

// =============== ratio =================
$ratio_9_16_H: 0.56;
$ratio_widget_W: 1;
$ratio_4_3_W: 4/3;
$ratio_banner_W: 1480/698;

$lg-Avatar: 18rem;

@import "./variables";
@import "./typography";
@import "./utilities";
@import "./_reset.scss";
@import "./mixins";

html {
    scroll-behavior: smooth;
}

/* react toast */
.Toastify__toast {
    font-size: 1.4rem;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background: $bg-gradient-1 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background: $warning-main !important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
    background: $error-main !important;
}

/* stripe payment css */
/*
.stripe-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 100%;
    padding: 10px 14px;
    font-size: 1em;
    font-family: "Source Code Pro", monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
        rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
}
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
        rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}*/

html body {
    @include scrollBar;
}

body div {
    @include scrollBarInBody;
}

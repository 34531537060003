@use "../../assets/styles/variables";
.avatar {
    width: 3.2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 300ms ease-in-out, background-color 300ms ease-in-out;
    &:active {
        transform: scale(0.9);
    }
    &:hover {
        background-color: variables.$background;
    }
}

.avatarMenu {
    position: absolute;
    top: 6.5rem;
    right: 2rem;
    z-index: 2;
    padding: 1.6rem 0.8rem;
    margin: 0;
    text-align: center;
    color: variables.$gray-900;
    font-weight: 600;
    background-color: variables.$background-paper;
    box-shadow: 6px 6px 15px variables.$gray-600;
    border-radius: 1rem;
    list-style: none;
    .item {
        padding: 0.8rem 1.6rem;
        border-radius: 1rem;
        transition: background-color 300ms ease-in-out;
        &:not(:first-child) {
            margin-top: 0.4rem;
            cursor: pointer;
            &:hover {
                background-color: variables.$purple-50;
            }
        }
    }
}

.closeMenu {
    position: fixed;
    inset: 0;
    z-index: 1;
}

@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/variables";

.listBtn:global(.MuiListItemButton-root) {
    padding-left: 2.4rem;
    margin-bottom: 1.6rem;
    &:hover {
        background: rgba(#ccc, 0.2);
    }

    &.active {
        background: rgba(#ccc, 0.5);
    }

    & li .itemText {
        & span {
            @include single-text-ellipsis;
            font-size: 1.4rem;
        }
        &.active span {
            font-weight: 900;
        }
    }
}
.searchWrapper {
    width: 80%;

    margin: 0 auto;
    margin-top: 2rem;
}
.searchInput {
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0px 12px;
    border-radius: 8px;
}

.actionWrapper {
    width: 80%;
    margin: 0 auto 48px;
    display: flex;
    flex-direction: column;
}

.actionWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 2rem;

    &.justifyEnd {
        justify-content: end;
    }

    .linkBtnWrap {
        display: flex;
        flex-wrap: wrap;
        bottom: 2rem;
        justify-content: center;
        margin: 2rem 1rem 2rem 1rem;
        padding-bottom: 2rem;
        color: $primary-main;
        transition: all 0.3s;

        &:after {
            content: "";
            display: block;
            width: 70%;
            height: 1px;
            background-color: $primary-main;
        }

        &:hover {
            cursor: pointer;
            font-size: 1.4rem;
            color: $primary-dark;
            transform: translateY(-2px);

            &:after {
                background-color: $primary-dark;
            }
        }

        .linkBtn {
            padding-right: 2rem;
            align-self: center;
            font-size: 1.4rem;
            text-align: center;
        }
    }
}

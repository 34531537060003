@use "../../../../assets/styles/variables";
@use "../../../../assets/styles/mixins";

.urlArrFields {
    position: relative;
    :global(.MuiOutlinedInput-input.MuiInputBase-input) {
        padding: 8.5px 14px;
        padding-right: 13.2rem;
        &:focus {
            background-color: variables.$background-paper;
        }
    }
    :global(.MuiInputBase-root.MuiOutlinedInput-root) {
        margin-bottom: 0;
    }
}
.addBtn:global(.MuiButton-root) {
    position: absolute;
    color: variables.$text-deepblue;
    top: 0.8rem;
    right: 0.6rem;
    max-height: 3.2rem;
    background-color: variables.$purple-100;
    color: variables.$purple-900;
    box-shadow: none;
    &:hover {
        background-color: variables.$purple-100;
    }
    &.disabled {
        color: variables.$gray-700;
        // background-color:variables.$gray-500 ;
        &:hover {
            cursor: auto;
        }
    }
}

.urlsContainer {
    margin: 0;
    margin-bottom: 2.4rem;
    padding: 0;
    list-style: none;
    li {
        margin-top: 0.6rem;
    }
    .urlBox {
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
    }
    .url {
        font-size: 1.4rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        & p {
            margin: auto 0;
        }
    }
}
.deleteBtnBox {
    display: flex;
    align-items: center;

    .deleteBtn {
        color: variables.$gray-600;
    }
}

.urlBox:has(.deleteBtnBox:hover) {
    background-color: variables.$purple-50;
    border: 1px solid variables.$purple-100;
}

.formWrapper {
    padding: 1rem;
    background-color: variables.$background-60;
    max-height: 45rem;
    margin-bottom: 2rem;
    overflow-y: auto;
    border-radius: 5px;
    gap: 4rem;
    padding-inline: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 1rem;
        .formWrapperCol {
            width: 100%;
            max-width: 100%;
        }
    }
}
.formWrapperCol {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    max-width: 48%;
    min-height: 60rem;
    // min-height: 200%;
}

%inputItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.6rem;
    align-items: center;
}

.inputItem_between {
    @extend %inputItem;
    flex-wrap: wrap;
    > h3 {
        align-items: center;
        flex: 1 1 30%;
    }
}

.inputItem_start {
    @extend %inputItem;
    gap: 4rem;
    justify-content: flex-start;
    align-items: center;
}

.inputColor {
    width: 35px;
    height: 35px;
    padding: 1px;
    border: 2px solid variables.$gray-light-4;
    border-radius: variables.$border-radius-default;
    cursor: pointer;

    > input[type="color" i] {
        transform: translate(-2px, -2px);
        width: 33px;
        height: 33px;
        border: none;
        padding: 0 1px;
        margin: 0;
        border-radius: variables.$border-radius-default;
        background-color: transparent;
    }
}

.btnSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid variables.$border-gray-1;
    .resetBtn {
        gap: 0;
        font-size: 1.4rem;
        background-color: variables.$secondary-light;
    }
}

@import "../../assets/styles/variables";

.toggleVideo {
    z-index: 1300;
    //造成頁面遮蔽
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
}

.videoBackground {
    z-index: 1300;
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
}

@use "sass:map";
@import "./variables";
@import "./functions";

@mixin rwd($name) {
    @media screen and (min-width: map-get($breakpoints, $name)) {
        @content;
    }
}

@mixin flex-box($flex-d, $justify-c, $align-i) {
    @if (
        map.has-key($flex-direction, $flex-d) or
            map.has-key($justify-content, $justify-c) or
            map.has-key($align-items, $align-i)
    ) {
        display: flex;
        flex-direction: map.get($flex-direction, $flex-d);
        justify-content: map.get($justify-content, $justify-c);
        align-items: map.get($align-items, $align-i);
        @content;
    } @else {
        @error "#{$flex-d} or #{$justify-c} or #{$align-i} is not a known key.";
    }
}

@mixin single-text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin multiple-text-ellipsis($lineNumber) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lineNumber;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
}

@mixin videoContainer($vw, $xsVw) {
    width: $vw;
    height: $vw * $ratio_9_16_H;
    @media screen and (max-width: 900px) {
        width: $xsVw;
        height: $xsVw * $ratio_9_16_H;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}

@mixin circleBtn($btn, $svg) {
    min-width: 0;
    padding: 0;
    height: $btn;
    width: $btn;
    svg {
        font-size: $svg;
        color: #fff;
    }
    @media screen and (max-width: 500px) {
        min-width: 0;
        width: calc(#{$btn} - 8px);
        height: calc(#{$btn} - 8px);
        svg {
            font-size: calc(#{$svg} - 6px);
        }
    }
}

@mixin textLink($flex) {
    display: flex;
    flex-direction: row;
    justify-content: $flex;
    gap: 0.5rem;
    align-items: center;
}

@mixin chatroomBoarder {
    border: 1px solid #cbd5e1;
    border-radius: 3vmin;
    box-shadow: 0 2px 5px #e2e8f0;
    -webkit-box-shadow: 0 2px 5px #e2e8f0;
    -moz-box-shadow: 0 2px 5px #e2e8f0;
    overflow: hidden;
}

@mixin chatWidgetBoarder {
    border: 1px solid $purple-100;
    border-radius: 3px;
    -webkit-box-shadow: 0 0 1px 1px #e2e8f0;
}

@mixin cancelBtnRoll {
    color: white;
    background-color: $primary-main;
    cursor: pointer;
    border-radius: 50px;
    transition: all 0.3s;

    &:hover {
        background-color: $info-main;
        color: $primary-dark;
        animation: animationRoll 0.3s 1 linear normal;
    }
}

@keyframes animationRoll {
    0% {
        transform: rotate(0deg) scale(1);
    }
    100% {
        transform: rotate(90deg) scale(1.1);
    }
}

@mixin scrollBar {
    &::-webkit-scrollbar {
        width: 12px;
        height: 10px;
        background-color: $purple-50;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $info-main;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: $info-dark;
    }
}

@mixin scrollBarInBody {
    &::-webkit-scrollbar {
        width: 8px;
        height: 6px;
        background-color: $purple-50;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: $info-dark;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: $purple-400;
        cursor: pointer;
        height: 10px;
        width: 10px;
    }
}

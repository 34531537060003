@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.listItem {
    display: block;
    padding: 0;
    & :global(.MuiListItemIcon-root) {
        min-width: 3.4rem;
    }

    & .listBtn:global(.MuiListItemButton-root) {
        padding-left: 3.2rem;
        &:hover {
            background: rgba(216, 216, 216, 0.8);
        }

        &.active {
            background: $gray-light-1;
        }

        & li .itemText {
            @include single-text-ellipsis;

            & span {
                font-size: 1.4rem;
            }
        }
    }
}

@use "/src/assets/styles/variables";

.checkboxInput {
    // padding: 2px;
    display: flex;
    align-items: center;
    width: 26px;
    height: 26px;
    overflow: hidden;
    padding: 2px;
    border: 2px solid variables.$gray-light-4;
    border-radius: variables.$border-radius-default;
    > input[type="checkbox" i] {
        &:checked::before {
            content: "";
            display: inline-block;
            position: relative;
            width: 18px;
            height: 18px;
            background-color: variables.$primary-main;
            border-radius: variables.$border-radius-default;
        }
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin: 0;
    }
}

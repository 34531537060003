@use "../../../../assets/styles/mixins" as mixins;
@use "../../../../assets/styles/variables" as variables;
%halfWrapper {
    flex: 1 1 40%;
    min-width: 300px;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    // height: 60rem;
    @include mixins.rwd(tablet-719) {
        width: 100%;
        min-width: 400px;
    }
}
.previewLayout {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 4rem;
    @include mixins.rwd(tablet-719) {
        flex-direction: row;
    }
}
.historySection {
    max-width: 760px;
    @extend %halfWrapper;
}
.handleSection {
    @extend %halfWrapper;
    min-width: 45vw;
    width: 100%;
}

.historyListContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    @extend %halfWrapper;
    > ul {
        padding: 0;
        height: 40vh;
        overflow-y: auto;
    }
}

.historyPreviewContainer {
    @extend %halfWrapper;
    padding: 1.2rem;
    background-color: rgb(245, 237, 255);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    > div {
        background-color: rgb(255, 255, 255);
        width: 100%;
        min-height: 150px;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
    }
}

.timeRangeContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
    max-width: 960px;
}

.btnGroup {
    display: inline-flex;
    gap: 1rem;
    flex-wrap: wrap;
    flex: 1;

    margin-bottom: 2rem;
}
.timeRangeInput {
    width: 17rem;
}

.dropdownWrapper:global(.MuiPaper-root) {
    display: flex;
    flex-grow: 1;
    width: 60%;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 0;
    background-color: transparent;
    > div:first-child {
        display: flex;
        flex-shrink: 0;
        font-weight: 600;
        color: variables.$text-gray-1;
    }
    > div:last-child {
        display: flex;
        flex-grow: 1;
    }
}

.dropdownSelect {
    width: 100%;
}

@use "../../assets/styles/variables";
.loadingBtn:global(.MuiLoadingButton-root) {
    padding: 0.6rem 4rem;
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
}
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: variables.$background-paper;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 3rem;
    min-width: 400px;
    max-width: 800px;
    min-height: 14rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.title {
    font-size: 1.8rem;
    font-weight: 600;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0; /* 為了消除 heading tag (h1,h2,...etc.) 的預設 margin，但又不想影響全域 */
    margin-bottom: 1rem;
}
.subTitle {
    font-size: 1.6rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
.contentWrapper {
    min-height: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    > div,
    p,
    form {
        margin: 1rem 0;
    }
}
.actionWrapper {
    display: flex;
    gap: 0.8rem;
    margin-top: 1rem;
    &:global(.MuiDialogActions-root) {
        justify-content: center;
        padding: 0;
    }
    & > button:global(.MuiButtonBase-root.MuiButton-root.MuiLoadingButton-root) {
        font-size: 1.6rem;
        letter-spacing: 0.2rem;
        max-width: 14rem;
    }
}

.countdownMessage {
    margin: 1rem 0 0 0;
    text-align: center;
    font-size: 1.6rem;
    color: #939094;
}

.saleBtn:global(.MuiLoadingButton-root) {
    padding: 2px 2rem;
    min-height: 34px;
}

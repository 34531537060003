.loading {
    position: absolute;
    inset: 0;
    background-color: #f7eff3;
}
.img {
    background-color: #f7eff3;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    animation: fadeIn 0.8s ease-in-out;
    // aspect-ratio: 300/320; //因為 Kiosk 的 Chrome 版本太舊不能用
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.circle {
    border-radius: 100%;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    display: block;
    animation: fadeIn 0.5s ease-in-out;
    max-width: 100%;
    max-height: 100%;
}

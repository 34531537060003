@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.wrapper {
    height: 100dvh;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eee4ff;
}

.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7eff3;
    padding: 5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    width: 45rem;
}

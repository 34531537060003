@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.wrapper {
    padding: 1rem;
    @include rwd(tablet-719) {
        margin-top: 2rem;
        min-width: 300px;
        border-radius: 6px;
        padding: 1.5rem 2rem;
    }
    &:global(.MuiPaper-root) {
        box-shadow: none;
    }
}
.headingBox {
    box-sizing: border-box;
    width: 100%;
    flex-wrap: wrap;
    @include flex-box(row, start, center);
    align-items: center;
    &.headingMB {
        margin-bottom: 2rem;
    }

    & .headingTitle {
        font-weight: bold;
        font-size: 2.4rem;
        margin: 0;
    }
    & .headingSubtitle {
        font-size: 1.6rem;
        margin: 0;
    }

    & .actionWrapper {
        // height: 4rem;
        height: fit-content;
        gap: 1rem;
        margin-left: auto;

        Button {
            min-width: auto;
            > * {
                // display: inline-block;
                margin: 0;
            }
        }
    }
}

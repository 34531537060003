@use "../../../assets/styles/variables" as variables;
@use "../../../assets/styles/mixins" as mixins;

.background {
    width: 100%;
    min-height: 100dvh;
    background-color: variables.$purple-100;
    background-image: url("../../../assets/images/bot-head.png");
    background-size: auto;
    background-repeat: no-repeat;
    background-position: right bottom;
}

.logoWrapper {
    padding-top: 2.8rem;
    padding-left: 6rem;
    img {
        width: 200px;
    }
}

.formWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    min-width: 200px;
    margin-top: 6rem;
    padding: 0 2rem;
    @include mixins.rwd(desktop-1080) {
        margin-top: 6rem;
        margin-left: 26rem;
        padding: 0;
    }
}

.form {
    width: 100%;
    .input {
        display: block;
        margin: 1rem 0;
        width: 100%;
        min-width: 300px;
        height: 7rem;
        input {
            background: variables.$background;
        }
    }
}

.resendTxt {
    color: #666;
    font-size: 1.6rem;
    font-weight: 500;
}

.resendBtn:global(.MuiButtonBase-root) {
    margin-top: 1.4rem;
    padding: 1.2rem 2rem;
}

.submitBox {
    text-align: end;
    margin-top: 2.4rem;
    .submitBtn {
        padding: 1.2rem 2rem;
        width: 100%;
        @include mixins.rwd(tablet-719) {
            width: auto;
        }
    }
}

@use "/src/assets/styles/variables";
@use "/src/assets/styles/mixins";

.filterWrapper {
    display: block;
    margin-bottom: 2rem;
    padding: 0rem;
    font-weight: 600;
    color: variables.$gray-light-3;
    &.noShadow {
        box-shadow: none;
    }
}
.selectName {
    display: inline;
    margin-right: 10px;
}
.filterInput {
    min-width: 150px;
    cursor: pointer;
    padding: 10px 30px 10px 8px;
    font-size: 1.6rem;
    border-radius: variables.$border-radius-default;
    appearance: none;
    &:hover,
    &:focus {
        background: variables.$info-light;
        outline: 1px solid variables.$primary-main;
    }
}
.filter {
    position: relative;
    display: inline;
}
.arrow {
    content: "";
    display: inline;
    position: absolute;
    top: 50%;
    transform: translateY(-40%) scale(1.3);
    right: 1rem;
    pointer-events: none;
}

@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

div:has(> .signInform) {
    margin: 4rem 2rem 0rem 2rem;
}
.signInform {
    color: $text-primary;
}
.signInArea {
    .inputBox {
        .input {
            display: block;
            margin: 1rem 0;
            &:not(:first-child) {
                margin-top: 4.6rem;
            }
            input {
                background-color: $background;
            }
        }
    }
}

.confirmCode {
    & > :global(.MuiFormControl-root.MuiTextField-root) > :global(.MuiInputBase-root.MuiOutlinedInput-root) {
        background: $background;
    }
}
.passwordHint {
    display: block;
    font-size: 1.3rem;
    color: $text-secondary;
    padding-left: 1rem;
    margin: 2rem 0;
}

.actionBox {
    height: 40dvh;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    @media screen and (max-width: 600px) {
        justify-content: center;
        height: 30dvh;
    }
}

.submitBox {
    text-align: right;
    & > button {
        padding: 1rem 3rem;
        font-size: 1.5rem;
        letter-spacing: 5px;
        transition: 0.3s all;
    }
}
.hintBox {
    justify-content: flex-end;
    margin-top: 4rem;
    align-items: center;
    display: block;
    text-align: center;
    @media (max-width: 500px) {
        display: block;
        text-align: center;
    }
    @media (max-width: 1536px) {
        display: block;
        text-align: center;
    }
    .hintBoxText {
        color: $gray-light-3;
    }
}
a {
    color: $primary-main;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.textLink {
    @include textLink(flex-end);
    justify-content: center;
    @media (max-width: 500px) {
        flex-wrap: wrap;
    }
    @media (max-width: 1536px) {
        justify-content: center;
    }
}
.enterpriseLoginLink {
    margin-bottom: 6rem;
}

@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.backBtn:global(.MuiButton-root) {
    display: none;

    @include rwd(tablet-719) {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 1200;
        display: flex;
        font-size: 1.5rem;
        font-weight: 600;
    }
}

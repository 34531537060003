@use "src/assets/styles/variables" as variables;
@use "src/assets/styles/mixins" as mixins;

.demoVideoContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    z-index: 1300;
    width: 60vw;
    height: 60vw * variables.$ratio_9_16_H;
    @media screen and (max-width: 900px) {
        width: 90vw;
        height: 90vw * variables.$ratio_9_16_H;
    }
}
.loadingFrame {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
}
iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.closeBtnWrap {
    position: absolute;
    top: -2rem;
    right: -2rem;
    z-index: 1;
}

.btnClose {
    @include mixins.cancelBtnRoll;
}

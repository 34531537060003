@use "../../assets/styles/mixins" as mixins;
.container:global(.MuiContainer-root) {
    // 把常駐專案列表藏起來
    max-width: 100%;
    // @include mixins.rwd(tablet-719) {
    //     max-width: 100%;
    // }
    // @include mixins.rwd(desktop-1080) {
    //     max-width: 95%;
    // }
    overflow-x: auto;
    & > :global(.MuiPaper-root) {
        box-shadow: none;
    }
}

.btnContainer {
    position: fixed;
    bottom: 9.6rem;
    right: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

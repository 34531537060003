@import "../assets/styles/variables";
@import "../assets/styles/mixins";

.main {
    //! 為了製作滿版無 Y 軸的頁面
    height: 100vh;
    inset: 0;
}

.logoWrapper {
    position: absolute;
    top: 4rem;
    left: 4rem;
    & img {
        width: 200px;
    }
}
.backgroundImg {
    background-position: 45% 0;
    background-size: cover;
    background-repeat: no-repeat;
    //! -1px 是為了不要在 900 的時候跑版
    @media (max-width: calc(900px - 1px)) {
        height: 60vh;
    }
    @media (max-width: 500px) {
        display: none;
    }
}

.imageContainer {
    width: 100%;
    height: 100%;
    position: relative;
}
.loginForm {
    height: 100%;
    background-color: rgb(238, 228, 255);
    padding: 4rem;
    background-image: url("../assets/images/bot-head.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right bottom;

    @media (max-width: 500px) {
        height: 100vh;
        padding: 1rem;
        display: flex;
        align-items: center;
    }
}
.formWrapper {
    margin: auto auto;
    align-items: center;
    width: 100%;
}

@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.wrapper {
    background-color: $background-paper;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // padding: 1rem;

    &:hover,
    &.dragover {
        opacity: 0.6;
    }
}

.uploadSection {
    @include flex-box(column, center, center);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23927DFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: 13rem;
    background-color: rgba($purple-400, 0.1);

    & .headingWrapper {
        //@include flex-box(column, center, center);
        text-align: center;
        padding: 8px;
        & > div {
            & img {
                width: 5rem;
            }
        }
    }

    .input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.fileListWrapper {
    margin: 0.8rem 0;
    max-width: 60%;
    // padding: 1rem;
    // background-color: rgba(#cfe3f3, 0.6);
    border-radius: $border-radius-default;
    & .fileList {
        @include flex-box(row, start, center);
        position: relative;
        margin: 0.8rem 0;
        justify-content: space-between;
        column-gap: 1rem;
        transition: background-color 300ms ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba($purple-100, 0.3);
            .actionIcon {
                transform: scale(1.05);
            }
        }
        & .fileRow {
            //display: flex;

            & img {
                height: 3.5rem;
                object-fit: contain;
            }
            & .info {
                // margin-left: 16px;
                & .fileName {
                    font-size: 1.4rem;
                    color: $gray-700;
                }
                & .fileSize {
                    font-size: 1.4rem;
                    color: $text-secondary;
                }
            }
        }

        & .actionIcon {
            color: $primary-main;
            padding: 0;
            transition: transform 100ms ease-in-out;
            // position: absolute;
            // right: 2rem;
            // top: 50%;
            // transform: translateY(-50%);
        }
    }
}

.uploadExplanation,
.uploadBeware {
    font-size: 1.4rem;
    color: $gray-700;
    margin: 0.8rem 0 0 0;
}

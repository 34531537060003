@use "../../assets/styles/variables" as variables;
@use "../../assets/styles/mixins" as mixins;

.box {
    margin-top: 5.6rem;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out;
    // 把常駐專案列表藏起來 /
    // @include mixins.rwd(tablet-lg) {
    //     margin-left: variables.$drawer-width;
    // }
    &.saleMode {
        margin-left: 0;
        padding: 0;
        @include mixins.rwd(tablet-719) {
            padding: 1rem 10rem 1rem 1rem;
        }
        @include mixins.rwd(desktop-1080) {
            padding: 2rem 12rem 1rem 12rem;
        }
    }
    &.sideBarOpen {
        margin-left: variables.$drawer-width;
    }
}

.main {
    padding: 0 0.8rem;
    position: relative;
}

@use "/src/assets/styles/variables";

.radioInput {
    display: none;
}
.radioInput:checked + .radioLabel {
    color: #fff;
    background-color: variables.$info-dark;
}

.radioLabel {
    display: inline-block;
    padding: 0.8rem 1.8rem;
    text-align: center;
    min-width: 8em;
    color: variables.$primary-dark;
    border-radius: 5rem;
    background-color: variables.$info-light;
    cursor: pointer;
    box-shadow: 1px 1px 4px rgba(26, 1, 138, 0.3);
}

.radioLabel:not(:nth-child(2)) {
    margin-left: 2rem;
}

.radioGroup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;
    gap: 2rem;
}

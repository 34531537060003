@import "../../../assets/styles/variables.scss";

.quickLogin {
    margin-top: 2rem;
    .btnQuickLoginContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        column-gap: 2rem;
    }
}

.titleQuickLoginContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        display: inline-block;
        height: 1px;
        width: 100%;

        background: $text-black;
    }
    .titleQuickLogin {
        flex-shrink: 0;
        color: $text-black;
        margin: 0 4rem;
        font-weight: 500;
        font-size: 2rem;
        @media (max-width: 500px) {
            margin: 0 2rem;
        }
    }
}

@import "../../../assets/styles/variables";

.form {
    max-width: 50rem;
    & > div {
        margin-bottom: 1rem;
    }
}
.buttonGroup {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem 0;
    justify-content: flex-end;
    align-items: center;
    color: $text-gray-2;
    gap: 2rem;
}

.loaderBox {
    position: relative;
    display: inline-flex;
}
.value {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@use "/src/assets/styles/variables" as variables;
@use "/src/assets/styles/mixins" as mixins;

.filterWrapper {
    display: flex;
    padding: 0rem;
    height: 67px;
    font-weight: 600;
    min-width: fit-content;
    justify-content: flex-start;
    color: variables.$gray-light-3;
    //@include mixins.rwd(tablet-719) {
    //    min-width: fit-content;
    //}
}
.selectName {
    display: flex;
    margin-right: 10px;
    margin-bottom: 24px;
    align-items: center;
}

.input {
    & > div {
        padding: 0;
    }
    & > div :global(.MuiInputBase-input.MuiOutlinedInput-input) {
        padding: 1rem;
        background: variables.$info-light;
        border-radius: variables.$border-radius-default;
        &:hover {
            background: variables.$background-paper;
        }
    }

    & > div :global(.MuiInputBase-adornedEnd) {
        background: variables.$info-light;
        border-radius: variables.$border-radius-default;
    }
}

@use "/src/assets/styles/variables";

.tab {
    a:global(.MuiButtonBase-root.MuiTab-root.Mui-selected) {
        background-color: #e5deff9e;
        color: variables.$purple-800;
        border-radius: 5px 5px 0px 0px;
    }
    a:global(.MuiButtonBase-root.MuiTab-root) {
        background-color: #ebe8e89e;
    }
}

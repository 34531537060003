@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.registerForm {
    margin: 4rem 2rem;
    color: $text-primary;
    height: auto;
}
.signInArea {
    // height: 16.5rem;
    @media screen and (max-width: 600px) {
        height: auto;
    }
    .inputBox {
        .input {
            display: block;
            margin: 1rem 0;
            width: 100%;
            min-width: 300px;
            height: 8rem;
            input {
                background: $background;
            }
        }
    }
}

.resentBtn {
    display: block;
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    padding: 1rem auto;
    letter-spacing: 2px;
}

.psText {
    color: $gray-light-3;
    display: flex;
    flex-wrap: wrap;
}

.actionBox {
    min-height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.submitBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: auto;
    gap: 2rem;

    @media screen and (max-width: 600px) {
        margin-top: 6rem;
        justify-content: center;
        & > * {
            padding: 1rem 2rem;
            font-size: 2rem;
            letter-spacing: 5px;
        }
    }
}

.textLink {
    margin-top: 4rem;
    flex-wrap: wrap;
    @include textLink(flex-end);
    @media screen and (max-width: 600px) {
        @include textLink(center);
        margin-top: 2rem;
    }
}
.psText {
    color: $gray-light-3;
    display: flex;
    flex-wrap: wrap;
}

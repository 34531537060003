@use "/src/assets/styles/variables";
@use "/src/assets/styles/mixins";

.pillTab {
    a:global(.MuiButtonBase-root.MuiTab-root) {
        margin-right: 1rem;
        background-color: variables.$purple-50;
        color: variables.$purple-600;
        border-radius: 50px;
        min-width: 50px;
        font-weight: 500;
        padding: 8px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 2rem;
    }
    a:global(.MuiButtonBase-root.MuiTab-root.Mui-selected) {
        background-color: variables.$info-main;
        color: variables.$purple-800;
        border-bottom: 0;
    }
    span {
        display: none;
    }
}
.scrollableX:global(.MuiTabs-scroller) {
    @include mixins.scrollBarInBody;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.mr-small {
    margin-right: 1.4rem;
}

.mt-medium {
    margin-top: 4.8rem;
}

.mt-small {
    margin-top: 2.5rem;
}

.mb-7 {
    margin-bottom: 7rem;
}
.mt-7 {
    margin-bottom: 7rem;
}

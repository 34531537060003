@use "../../../../assets/styles/variables.scss" as variables;
@use "../../../../assets/styles/mixins.scss" as mixins;

.chartsLayout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
    @include mixins.rwd(desktop-1080) {
        gap: 4rem;
        flex-direction: row;
    }
}
.rwdDisplayNone {
    display: none;
    @include mixins.rwd(tablet-719) {
        display: flex;
    }
}

.lastMB {
    margin-bottom: 0;
}

@use "/src/assets/styles/mixins" as *;

.chatWidgetMobile {
    @include chatroomBoarder;
}
.widgetLoader {
    @include chatroomBoarder;
    background: #fff;
    height: 76dvh;
    width: 100%;
    max-width: 600px;
    display: grid;
    place-items: center;
}

.chatBoxWrapper {
    position: relative;
    width: 100%;
    max-width: 600px;
}

.dropdownWrapper:global(.MuiPaper-root) {
    display: flex;
    flex-grow: 1;
    margin: 0 2rem 1rem 2rem;
    align-items: center;
    background-color: transparent;
    > div:first-child {
        display: flex;
        flex-shrink: 0;
    }
    > div:last-child {
        display: flex;
        flex-grow: 1;
    }
}

.dropdownSelect {
    width: 100%;
}

@import "../../../../assets/styles/variables";
@import "../../../../assets/styles/mixins";

.rowText {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;

    // & > span {
    //     font-weight: bold;
    //     @include single-text-ellipsis;
    //     padding-bottom: 0.6rem;
    //     font-size: 1.8rem;
    // }
    // & > p {
    //     font-size: 1.6rem;
    //     text-align: justify;
    // }
}
.avatar {
    width: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
    }
}
.listItem {
    transition: transform 300ms ease-in-out;
    border-radius: 5px;
    width: 100%;
    transition: all 0.3s;
    margin-bottom: 1rem;
    &:first-child {
        margin-top: 0.5rem;
    }
    &:global(.MuiListItem-root) {
        background-color: $background;
        padding: 1rem;
    }
    &:hover {
        transform: translateY(-5px);
        background-color: rgb(245, 237, 255);
        border-radius: 10px;
    }
    > div {
        width: 100%;
    }
}

.paginationWrapper {
    margin-top: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

// .source {
//     background-color: $primary-dark;
//     border-radius: 5px;
//     color: $info-light;
//     width: fit-content;
//     padding: 0 1rem;
// }

.message {
    display: flex;
    gap: 2rem;
    width: 80%;

    > p:first-child {
        flex-shrink: 0;
    }

    > p:last-child {
        flex-grow: 1;
        min-width: 0;
        @include single-text-ellipsis;
    }
}

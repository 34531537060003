@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/variables";
.knowledgesSection {
    margin-top: 4.8rem;
}

.card:global(.MuiCard-root) {
    cursor: pointer;
    width: 100%;
    min-width: 100px;
    height: 100%;
    min-height: 220px;

    position: relative;

    .knowledge {
        @include flex-box(column, center, center);
        height: 100%;
        & .imgWrapper {
            flex-grow: 1;
            //position: relative;
            @include flex-box(column, center, center);
        }
        & .info {
            height: 20%;
            & .text {
                font-weight: bold;
                font-size: 2.2rem;
                color: $background-paper;
            }
        }
    }
}

.selectedCard {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(10, 10, 10, 0.5);
    height: 100%;
    width: 100%;
    @include flex-box(column, center, center);
    z-index: 2;
}

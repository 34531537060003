@use "../../../../assets/styles/variables.scss" as variables;
@use "../../../../assets/styles/mixins.scss" as mixins;

@mixin chart($chartHeight) {
    width: 100%;
    min-width: 300px;
    display: block;
    height: $chartHeight;
    border: 1px solid variables.$purple-50;
    border-radius: variables.$border-radius-default;
}

.chartContainerSm {
    @include chart(200px);
    min-width: 250px;
    @include mixins.rwd(tablet-719) {
        @include chart(300px);
    }
}
.chartContainerLg {
    @include chart(200px);
    @include mixins.rwd(tablet-719) {
        @include chart(300px);
    }
}

.chartGroup {
    width: 100%;
    margin-bottom: 1rem;
    & > div:last-child {
        display: inline;
        padding-left: 1rem;
        line-height: 3rem;
    }
}

@use "../../assets/styles/variables" as variables;
@use "../../assets/styles/mixins" as mixins;

.navBox {
    // padding-inline: 6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: variables.$topNav-Height;
    @include mixins.rwd(tablet-719) {
        padding-inline: 4rem;
    }
}

.appBar:global(.MuiAppBar-root) {
    background-color: variables.$purple-100;
    box-shadow: none;
}

.logoWrapper {
    height: variables.$topNav-Height;
    padding: 1.3rem 0;
    & img {
        height: 100%;
    }
}
.actionWrapper {
    display: flex;
    gap: 3rem;
    align-items: center;
}
.filled {
    color: variables.$primary-main;
}

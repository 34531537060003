@use "../../assets/styles/variables" as variables;
@use "../../assets/styles/mixins" as mixins;

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(22, 22, 22);
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
    min-width: 800px;
    max-width: 1000px;
    height: 50vh;
    display: flex;
    .cropContainer,
    .previewContainer {
        padding: 2rem;
        display: flex;
        box-sizing: border-box;
        width: 50%;
        height: auto;
        flex-direction: column;
        align-items: center;
    }
}
.actionWrapper {
    display: flex;
    justify-content: center;
    gap: 0.8rem;
    margin-top: 1rem;
    & > button:global(.MuiButtonBase-root.MuiButton-root.MuiLoadingButton-root) {
        font-size: 1.6rem;
        letter-spacing: 0.2rem;
        max-width: 14rem;
    }
}

.cropContainer {
    border-right: 1px solid rgb(59, 0, 0);
    justify-content: space-between;
    .cropToolWrap {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.previewContainer {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    > div {
        width: 100%;
        height: 100%;
        background-color: #9780a7;
    }
}
.cropImg {
    // height: 28rem;
    width: full;
    background-image: linear-gradient(to bottom, rgb(211, 196, 248) 0%, #e7ebee 100%);
    object-fit: contain;
    border-radius: 5px;
}
//copy from src/components/form/FormUploadFile.module.scss，如需異動請一起變更
.wrapper {
    background-color: variables.$background-paper;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &:hover,
    &.dragover {
        opacity: 0.6;
    }
}

.uploadSection {
    @include mixins.flex-box(column, center, center);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23927DFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 16px;
    position: relative;
    width: 100%;
    height: 13rem;
    background-color: rgba(variables.$purple-400, 0.1);

    & .headingWrapper {
        text-align: center;
        padding: 8px;
        & > div {
            & img {
                width: 5rem;
            }
        }
    }

    .input {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.fileListWrapper {
    margin: 0.8rem 0;
    max-width: 60%;
    border-radius: variables.$border-radius-default;
    & .fileList {
        @include mixins.flex-box(row, start, center);
        position: relative;
        margin: 0.8rem 0;
        justify-content: space-between;
        column-gap: 1rem;
        transition: background-color 300ms ease-in-out;
        cursor: pointer;
        &:hover {
            background-color: rgba(variables.$purple-100, 0.3);
            .actionIcon {
                transform: scale(1.05);
            }
        }
        & .fileRow {
            & img {
                height: 3.5rem;
                object-fit: contain;
            }
            & .info {
                // margin-left: 16px;
                & .fileName {
                    font-size: 1.4rem;
                    color: variables.$gray-700;
                }
                & .fileSize {
                    font-size: 1.4rem;
                    color: variables.$text-secondary;
                }
            }
        }

        & .actionIcon {
            color: variables.$primary-main;
            padding: 0;
            transition: transform 100ms ease-in-out;
        }
    }
}

.uploadExplanation,
.uploadBeware {
    font-size: 1.4rem;
    color: variables.$gray-700;
    margin: 0.8rem 0 0 0;
}
.bg-none {
    background-color: transparent;
    &:hover {
        background-color: transparent;
    }
}
.previewVideoBox {
    position: relative;
    margin-top: 2rem;
    margin-right: 1rem;
    min-height: 20rem;
}
.deleteBtnWrap {
    position: absolute;
    top: -1rem;
    right: -1rem;
    z-index: 1;
    &:hover {
        svg {
            scale: 1.4;
        }
    }
}

.btnDelete {
    width: 2rem;
    height: 2rem;
    @include mixins.cancelBtnRoll;
}
.deleteBtn:global(.MuiButton-root) {
    min-width: auto;
    padding: 0.5rem;
    margin: 0 auto;
    :global(.MuiButton-icon) {
        margin: 0;
    }
}

@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";
/**.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
**/
:global(.MuiDrawer-paper) {
    box-sizing: border-box;
    width: $drawer-width;
    margin-top: $topNav-Height;
    padding-top: 12px;
    & .drawerContent {
        @include flex-box(column, center, center);

        height: 100%;
        display: flex;
        flex-direction: column;

        & .navWrapper {
            flex-grow: 1;
            width: 100%;
            text-align: center;
        }

        & .projectsNav {
            width: 100%;
            @include flex-box(column, center, center);
            min-height: 300px;

            height: 100%;

            & .navContent {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                flex-grow: 1;
                width: 100%;
                overflow: auto;

                & .headingText {
                    font-size: 2rem;
                    padding-left: 2.4rem;
                }
            }
        }
    }
}
.navTitle {
    padding: 20px 30px;
    font-size: 2rem;
    font-weight: 500;
    text-align: left;
    border-bottom: 1px solid #e5deff;
}
.navContent {
    display: flex;
    flex-direction: column;
    padding: 6px;
    text-align: left;
}
.navItem {
    padding: 8px 34px;
    font-size: 1.4rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
    cursor: pointer;
    &:hover {
        background-color: #e5deff96;
    }
}

.activeNavItem {
    background-color: #e5deff;
    color: #6e41e2;
    font-weight: 600;
}

@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.pageBox {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    gap: 2rem;
    border: 1px solid $border-gray-1;
    border-radius: $border-radius-default;
    @include rwd(desktop-1080) {
        padding: 2rem 1.4rem;
        width: 100%;
        flex-direction: row;
    }
}

.contentBox {
    flex: 0 1 100%;
    max-width: 100%;
    position: relative;
    @media screen and (max-width: 1100px) {
        width: 100%;
        max-width: 100%;
    }
}

.maxWidth {
    max-width: 50%;
    @media screen and (max-width: 1100px) {
        max-width: 100%;
    }
}
.chatBox {
    // @include chatroomBoarder;

    flex: 1 1 50%;
    min-width: 50%;
    align-self: start;
    min-height: 60dvh;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1100px) {
        //min-height: 60rem;
        width: 100%;
    }

    .chatWidgetMobile {
        height: 80dvh;
        padding: 1rem;
        width: 100%;
    }
}

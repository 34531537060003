@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.greeting {
    line-height: 1.1;
    margin: 0 0 2rem 0;
    letter-spacing: 1rem;
    color: $primary-main;
    font-family: Impact, "Arial Narrow Bold", sans-serif;
    text-transform: capitalize;

    .hello {
        font-size: 3.5rem;
        font-weight: 600;
        word-break: break-all;
        @include multiple-text-ellipsis(2);
    }
    .welcome {
        font-size: 4.5rem;
        font-weight: 900;
        word-break: break-all;
        @include multiple-text-ellipsis(2);
    }

    @media (max-width: 900px) {
        .hello {
            font-size: 3rem;
        }
        .welcome {
            font-size: 4rem;
        }
    }
    @media (max-width: 500px) {
        .hello {
            font-size: 3rem;
            margin-left: 10vw;
            margin-bottom: 2rem;
        }
        .welcome {
            margin-left: 30vw;
            font-size: 4rem;
        }
    }
}

.wrapper {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
}

.chatBoxClass {
    padding: 6px;
    background: #fff;
    height: 76dvh;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

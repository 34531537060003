@use "/src/assets/styles/variables";
.buttonsBox {
    display: flex;
    gap: 1.2rem;
    justify-content: flex-end;
    > button {
        box-shadow: none;
    }
}
.submitBtn:global(.MuiButton-root) {
    &:hover {
        color: variables.$text-deepblue;
        color: variables.$text-white;
    }
}
.cancelBtn:global(.MuiButton-root) {
    &:hover {
        color: variables.$info-light;
        background-color: variables.$gray-500;
    }
}

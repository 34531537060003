@use "src/assets/styles/mixins" as mixins;
@use "src/assets/styles/variables" as variables;

.fileRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    background-color: variables.$background-paper;
    &:hover {
        background-color: variables.$info-light;
    }
}
.previewAssetBox {
    display: flex;
    width: 90%;
    cursor: pointer;
    align-content: center;
    justify-content: flex-start;
    gap: 1rem;
    .textWrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 80%;
        // flex: 0 1 100%;
        p {
            @include mixins.single-text-ellipsis;
        }
    }
}
.imgWrap {
    display: flex;
    aspect-ratio: 1/1;
    width: 5rem;
    border: 1px solid variables.$border-gray-1;
}
.previewImg {
    aspect-ratio: 1/1;
    width: 100%;
}

.deleteBtn:global(.MuiButton-root) {
    display: flex;
    min-width: auto;
    padding: 0.5rem;
    margin: auto 0;
    :global(.MuiButton-icon) {
        margin: 0;
    }
    &:hover {
        animation: animationRoll 0.3s 1 linear normal;
    }
}

@import "../../assets/styles/variables";

.customSelect {
    & > :global(.MuiInputBase-root.MuiFilledInput-root) {
        background: $background-paper;
        border-radius: $border-radius-default;
        border: 1px solid $border-gray-2;
        &:hover {
            background: $info-light;
        }
        & > :global(.MuiSelect-select.MuiFilledInput-input) {
            &:focus-within {
                background: $info-light;
            }
        }
    }
    div:global(.MuiInputBase-input.MuiFilledInput-input) {
        padding: 1rem;
    }
    div:global(.MuiInputBase-input.MuiOutlinedInput-input) {
        padding: 1.3rem;
    }

    & > :global(.MuiInputBase-root.MuiOutlinedInput-root) {
        background: $background-paper;
        border-radius: $border-radius-default;
        &:hover {
            background: $info-light;
        }
        &:focus-within {
            background: $info-light;
        }
    }
}
// MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input

@use "../../assets/styles/variables" as variables;
@use "../../assets/styles/mixins" as mixins;
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 50vw;
    max-height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    background-color: rgb(22, 22, 22);
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.3);
    overflow: auto;
    transform: translate(-50%, -50%);
    @include mixins.rwd(tablet-719) {
        flex-direction: row;
        overflow: auto;
    }
    .cropContainer {
        padding: 2rem;
        display: flex;
        width: 80%;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        @include mixins.rwd(tablet-719) {
            padding: 2rem 4rem;
            width: 50%;
        }
        &.noPreview {
            width: 100%;
            justify-content: center;
            align-items: center;
        }
        .cropToolWrap {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .previewContainer {
        width: 100%;
        @include mixins.rwd(tablet-719) {
            padding: 1rem;
            width: 60%;
        }
        @include mixins.rwd(desktop-1080) {
            width: 50%;
            padding: 0;
        }
        .baseWrapper {
            padding: 2rem 4rem;
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            border-top: 2px dotted variables.$info-main;
            @include mixins.rwd(tablet-719) {
                border-left: 3px dotted variables.$info-main;
                border-top: none;
            }
        }
    }
}

.actionWrapper {
    gap: 1.6rem;
    .button {
        font-size: 1.6rem;
        max-width: 14rem;
        padding: 1rem;
        line-height: 2rem;
        letter-spacing: 0;
        @include mixins.rwd(tablet-719) {
            letter-spacing: 0.2rem;
        }
    }
}

.cropImg {
    background-image: url("../../assets/images/pngBackground.png");
    background-repeat: repeat;
    height: 100%;
    width: 100%;
    &.noPreviewImage {
        max-height: 65vh !important; // 強制覆蓋掉原本的max-height ，因為 react crop 預設的style是max-height inherit
        width: auto;
    }
}
.preview {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
$baseHeight: 22vh;

.previewWidgetWrapper {
    width: $baseHeight * variables.$ratio_widget_W;
    height: $baseHeight;
    margin: 1rem auto 0;
    @include mixins.rwd(tablet-719) {
        margin: 0 auto;
    }
}
.ratio_1_1 {
    width: $baseHeight;
    height: $baseHeight;
}
.ratio_4_3 {
    height: $baseHeight;
    width: $baseHeight * variables.$ratio_4_3_W;
}
//*0.7避免寬度過寬超出畫面
.ratio_banner {
    height: $baseHeight * 0.5;
    width: $baseHeight * 0.5 * variables.$ratio_banner_W;
}
.previewFreeWrapper {
    width: fit-content;
    height: fit-content;
    max-height: $baseHeight;
    margin: 1rem auto 0;
    @include mixins.rwd(tablet-719) {
        margin: 0 auto;
    }
}
.infoTitle {
    display: flex;
    justify-content: center;
    font-size: 1.6rem;
    font-weight: 500;
    margin-top: 1rem;
    margin-bottom: 0;
}
.previewWrapper {
    height: 300px;
    @include mixins.rwd(tablet-719) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        gap: 1rem;
    }
}

.flexPreviewMode {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .baseWrapper {
        padding: 1rem;
        @include mixins.rwd(tablet-719) {
            padding: 2rem 4rem;
            display: flex;
            align-items: center;
        }
    }
    .previewWrapper {
        align-items: center;
        justify-content: flex-start;
    }

    .closeWrapper {
        padding: 0;
        margin: 0 auto;
    }
}

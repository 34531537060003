.wrapper {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    margin-top: -4px; // 4 是 drawer - header 的差值
    height: calc(100dvh - 56px); // 56 是 header 的高度
}
.httpStatus {
    display: inline-block;
    font-size: 20rem;
    line-height: normal;
}
.content {
    text-align: center;
}
.title {
    margin: 0;
    color: transparent;
    background: linear-gradient(360deg, rgba(93, 46, 251, 1) 0%, rgba(229, 222, 255, 1) 50%, rgba(93, 46, 251, 1) 100%);
    background-clip: text;
}
.description {
    font-weight: 700;
    text-transform: capitalize;
}

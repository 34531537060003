@import "./variables";

.headingPrimary {
    margin: 0 0 6rem 0;
    text-transform: uppercase;

    &--main {
        font-size: 4rem;
        font-weight: 700;
    }

    &--sub {
        font-size: 2.2rem;
        font-weight: 400;
    }
}

.headingSecondary {
    margin: 0;
    font-size: 2.8rem;
    text-transform: uppercase;
    font-weight: 700;
}

.headingTertiary {
    margin: 0;
    font-size: $font-size-default;
    font-weight: 700;
    text-transform: uppercase;
}

.inputLabel {
    margin: 0;
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    margin: 0;
    font-size: $font-size-default;
    color: $text-secondary;
}

.primary-title {
    margin: 0;
    font-size: $font-size-default;
    line-height: 1.5;
    color: $primary-main;
    font-weight: 700;
}

.inlineTitle {
    font-size: 2.2rem;
}

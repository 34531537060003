@use "src/assets/styles/mixins";
@use "src/assets/styles/variables";

:root {
    --height: 65dvh;
}

.wrapper {
    @include mixins.chatWidgetBoarder;
    width: 100%;
    background-color: variables.$background;
    iframe {
        min-height: var(--height);
        z-index: 2;
        position: relative;
    }
}
.wrapper:has(.chatWidgetMobile) {
    background: variables.$bg-gradient-2;
}

.chatWidgetContainer {
    min-width: 300px;
    height: var(--height);
    position: relative;
}
.chatWidgetMobile {
    @include mixins.chatroomBoarder;
    margin: 2rem auto;
    width: 40%;
}

.skeletonWrapper {
    z-index: 1;
    padding: 2rem;
    gap: 2rem;
    width: 100%;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    height: var(--height);
    overflow: hidden;
}

.none {
    display: none;
}
